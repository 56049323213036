import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import "../../css/components/Footer.scss";

import Hungary from "../../images/home/footer/hungary.png";
import drbLogo from "../../images/home/footer/drb.services.png";
import backToTop from "../../images/svg/back-to-top.svg";

const Footer = () => {
  const handleScrollToTop = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  const queriedData = useStaticQuery(graphql`
    query FooterQuery {
      strapiFooter: strapiFooter {
        title
        description {
          data {
            description
          }
        }
        sitemap {
          data {
            sitemap
          }
        }
        logo {
          localFile {
            url
          }
        }
      }
    }
  `);

  const strapiFooterLogo = queriedData.strapiFooter.logo.localFile.url;
  const strapiFooterTitle = queriedData.strapiFooter.title || "URBEN DESIGN GROUP KFT";
  const strapiFooterDescription = queriedData.strapiFooter.description.data.description || "Description comes here";
  const strapiFooterSitemap = queriedData.strapiFooter.sitemap.data.sitemap || "Description comes here";

  /* eslint-disable */
  return (
    <>
      <footer className="footer">
        <img className="back-to-top" title="Vissza a tetejére" src={backToTop} alt="back to top button" onClick={handleScrollToTop} />
        <Container fluid>
          <Row>
            <Col className="logo-col d-flex justify-content-sm-center" md={3} lg={3}>
              <img src={strapiFooterLogo} alt="Urben Design Group" />
            </Col>
            <Col className="about-col" md={4} lg={3}>
              <div className="content">
                <ReactMarkdown children={strapiFooterSitemap} />
              </div>
            </Col>

            <Col md={5} lg={6} className="empty-col">
              <div className="content">
                <div className="description">
                  <h2>{strapiFooterTitle}</h2>
                  <ReactMarkdown children={strapiFooterDescription} />
                </div>
                <div className="creators">
                  <img src={Hungary} alt="Hungary" style={{ marginTop: "36px" }} />
                  <div>
                    Design –{" "}
                    <a href="http://krisztaszegedi.com/" className="yellow-text">
                      Kriszta Szegedi.
                    </a>
                  </div>
                  <div style={{ marginTop: "36px" }}>
                    <span>Development –</span>
                    <a href="https://drb.services/">
                      <img src={drbLogo} alt="" className="drb-logo" />
                      <span>Services</span>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";

import "../../../css/components/Header.scss";
import Logo from "../../../images/Urben-Design-Group.png";

const Header = () => {
  const queriedData = useStaticQuery(graphql`
    query HeaderQuery {
      site: site {
        siteMetadata {
          siteUrl
        }
      }
      allStrapiNavigation: allStrapiNavigation(sort: { fields: order, order: ASC }) {
        nodes {
          slug
          id
          title
          order
        }
      }
    }
  `);
  const siteUrl = queriedData.site.siteMetadata.siteUrl;
  const allStrapiNavigation = queriedData.allStrapiNavigation;

  return (
    <header className="header">
      <Navbar collapseOnSelect expand="xxl" style={{ backgroundColor: "#FFFFFF !important", height: "80px" }}>
        <Container fluid className="p-0">
          <h1 className="mb-0">
            <a href={siteUrl} className="navbar-brand">
              <img src={siteUrl + Logo} width="126.09" height="50" className="d-inline-block align-top" alt="Urben Design Group" />
            </a>
          </h1>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mobile-menu-container" style={{ zIndex: 1000, backgroundColor: "white" }}>
            <Nav className="d-flex">
              {allStrapiNavigation.nodes.map((navItem) => {
                if (navItem.slug.indexOf("#") > 0) {
                  return (
                    <AnchorLink key={navItem.id} to={`/${navItem.slug}`} data-rr-ui-event-key="#home" className="nav-link" activeClassName="active">
                      {navItem.title}
                    </AnchorLink>
                  );
                } else {
                  return (
                    <Link key={navItem.id} to={`/${navItem.slug}`} data-rr-ui-event-key="#home" className="nav-link" activeClassName="active">
                      {navItem.title}
                    </Link>
                  );
                }
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
